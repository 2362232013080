import { createAction, props } from '@ngrx/store';
import { Lead } from '@models/lead.interface';
import {Timeline} from "@models/timeline.interface";

export const addLead = createAction('[Lead] Add Lead', props<{ lead: Lead }>());

export const removeLead = createAction('[Lead] Remove Lead', props<{ leadId: string }>());

export const setSelectedLead = createAction('[Lead] Set Selected Lead', props<{ lead: Lead }>());

export const updateSelectedLead = createAction('[Lead] Update Selected Lead', props<{ lead: Partial<Lead> }>());

export const getSalesLeadsAction = createAction('[Lead] Get Sales Leads');

export const getSalesLeadsActionSuccess = createAction('[Lead] Get Sales Leads Success', props<{ leads: Lead[] }>());

export const getSalesLeadsActionFailure = createAction('[Lead] Get Sales Leads Failure');

export const getPurchasesLeadsAction = createAction('[Lead] Get Purchases Leads');

export const getPurchasesLeadsActionSuccess = createAction('[Lead] Get Purchases Leads Success', props<{ leads: Lead[] }>());

export const getPurchasesLeadsActionFailure = createAction('[Lead] Get Purchases Leads Failure');

export const getLeadTimeline = createAction('[Lead] Get Lead Timeline', props<{ lead: Lead }>());

export const getLeadTimelineSuccess = createAction('[Lead] Get Lead Timeline Success', props<{ leadTimeline: Timeline }>());

export const getLeadTimelineFailure = createAction('[Lead] Get Lead Timeline Failure');
